.margin20 {
    margin: 20px;
}

form textarea {
    width: 100%;
    height: 10em;
    border-radius: 3px;
    border:1px solid #ccc;
}

form {
    margin-bottom: 20px;
}
