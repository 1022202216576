
.navbar {
  margin: 0;
  border-left:none;
  border-right:none;
  border-radius: 0;
}

.navbar-default.navbar {
  background-color: #fff;
  text-transform: uppercase;
  font-weight: bold;
}
.navbar.navbar-inverse {
  background-color: #2b3a43;
}

.navbar-default.navbar-nav > li > a {
    color: #191818;
}

.navbar-default.navbar .navbar-nav > li {
  border-bottom: 5px solid transparent;
}


.navbar-default.navbar .navbar-nav > li.active {
  border-color: #a1c3a6
}

.navbar-default.navbar .navbar-nav > li:hover {
  border-color: #58c3af;
}

.navbar-default.navbar .navbar-nav > li > a {
  background-color: #fff!important;;
}

.navbar-inverse .navbar-collapse {
    border: none;
}

.navbar-inverse .navbar-nav > .active > a, .navbar-inverse .navbar-nav > .active > a:hover, .navbar-inverse .navbar-nav > .active > a {
  background-color: #374b56;
}

.yamm .dropdown.yamm-fw .dropdown-menu {
  padding: 0;
}
.yamm .yamm-content {
  background-color: #f4f7f9;
}
.yamm .yamm-content .nav {
  padding: 7px;
}
.yamm .yamm-content .nav-pills > li > a {
  text-decoration: none;
  color: #4a4747;
}
.yamm .yamm-content .nav-pills > li > a:hover {
  background-color: #fff;
  color: #a5a3a3;
}

.navbar-default.navbar-nav > .open > a, .navbar-default .navbar-nav > .open > a:hover, .navbar-default .navbar-nav > .open > a:focus {
  background-color: #ffffff;
  border-color: #58c3af;
}

.navbar-default .yamm .yamm-content .yamm-row > :not(last-child) {
  border-right: 1px solid rgba(0, 0, 0, .15);
}

.yamm .yamm-row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
}
.yamm .yamm-row > [class*='col-'] {
  display: flex;
  flex-direction: column;
}

.navbar-default ul.nav li.dropdown:hover > ul.dropdown-menu {
    display: block;
}
