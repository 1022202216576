@font-face {
    font-family: Circe;
    src: url(/static/fonts/Circe-ExtraLight.woff);
    src: url(/static/fonts/Circe-ExtraLight.svg);
    src: url(/static/fonts/Circe-ExtraLight.eot);
    src: url(/static/fonts/Circe-ExtraLight.ttf);
}

:focus {
  outline: -webkit-focus-ring-color auto 0!important;
}

body {
  font-family: 'PT Sans', sans-serif;
}

.footer {
    padding: 20px;
    background: #474754;
    background: linear-gradient(to bottom, #5c5d68, #474754);
    color: #D5DAE4;
}

footer .row > div:not(:last-child) {
    border-right: solid 1px #f4faff;
}

footer a,
footer a:hover,
footer a:active {
  margin-right: 10px;
  color: #f5f5f5!important;
  text-decoration: none!important;
}

footer a:hover {
  text-decoration: none;
  color: #d3e9f5;
}

.btn-seawave {
  color: #fff;
  background-color: #58c3af;
  border-color: #58c3af;
}

.btn-seawave:hover {
  color: #fff;
  background-color: #84d0c0;
  border-color: #58c3af;
}

textarea:hover,
input:hover,
textarea:active,
input:active,
textarea:focus,
input:focus,
button:focus,
button:active,
button:hover,
label:focus,
.btn:active,
.btn.active
{
  outline:0!important;
  -webkit-appearance:none;
}

button:focus,
button:active,
button:hover {
  color:#fff;
}

.col-xs-15,
.col-sm-15,
.col-md-15,
.col-lg-15 {
    position: relative;
    min-height: 1px;
    padding-right: 10px;
    padding-left: 10px;
}

body > .scrollToTop {
    width: 50px;
    height: 50px;
    border-radius: 7px;
    padding: 10px;
    text-align: center;
    background: #f5f5f5;
    font-weight: 700;
    color: #444;
    position: fixed;
    left: 75px;
    bottom: 40px;
    z-index: 1000;
    display: none;
    border: 2px solid #a5a5a5;
    opacity: 0.8;
}
body > .scrollToTop > i {
  vertical-align: middle;
}
body > .scrollToTop:hover{
	text-decoration:none;
}

.overflowxscroll {
  overflow-x: scroll;
}
