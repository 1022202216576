.row {
  margin-right: 0;
  margin-left: 0;
}

.row.top-menu {
    margin: 10px 20px;
}

.row.top-menu > div {
    /*height: 60px;*/
}

.row.top-menu .logo img {
    max-height: 65px;
}

.row.top-menu .search > button {
  margin-top: 12px;
  font-size: 18px;
}

.row.top-menu .search form button {
  padding: 3px;
  margin: 3px;
}

.row.top-menu .search form {
  margin: 0;
}

.row.top-menu .lk {
  font-size: 15px;
  text-transform: none;
}

.row.top-menu .lk a {
  font-family: "Circe",Arial,Helvetica,sans-serif;;
  color: #727271;
  font-size: 14px;
  display: block;
}
.row.top-menu .lk a {
  text-decoration: none;
}

.top-second-menu {
    background: #474754;
    background: linear-gradient(to top, #5c5d68, #474754);
    border-radius: 0;
}

.top-second-menu .navbar-nav>li>a {
    color: #D5DAE4;
}

.top-second-menu .container-fluid {
    padding-left: 0;
    padding-right: 0;
}

.top-second-menu.navbar-inverse {
    border-color: transparent;
}

.service-text {
    font-family: "PT Sans", sans-serif;
    text-transform: uppercase;
}

.page-content {
    margin-top: 20px;
}

.nav-pills > li > a {
    border-radius: 0;
}

.nav-pills > li > a.active {
    background-color: #dadada;
}

.white-bg:not(:last-of-type):not(:first-of-type) {
  padding-bottom: 20px;
}

.gray-bg:not(:last-of-type):not(:first-of-type) {
  padding-bottom: 20px;
}

.white-bg {
  background-color: #fff;
}

.gray-bg {
  background-color: #f4f7f9;
}

.blue-bg {
  background-color: #0b3848;
  color: #fff;
}

.slick-carousel .item {
  margin: 10px;
}

@media screen and (min-width: 768px) {
  .carousel-caption {
    right: 5%;
    left: 5%;
  }
}

a.notextdecoration:hover {
  text-decoration: none;
}
